import { ApplicationRef, Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-responsive-helper',
  templateUrl: './responsive-helper.component.html',
  styleUrls: ['./responsive-helper.component.scss'],
})
export class ResponsiveHelperComponent implements OnInit {
  public env: any = environment;
  scrolled: boolean = false;

  constructor() { }

  ngOnInit(): void {
    window.addEventListener('scroll', () => {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        this.scrolled = true;
      }
      else {
        this.scrolled = false;
      }
    });
  }

  backToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };


}
