import { Injectable } from '@angular/core';

const USER_KEY = 'auth-user';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  clean(): void {
    window.localStorage.removeItem(USER_KEY);
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('theme');
  }

  public saveUser(user: any, token: any): void {
    window.localStorage.removeItem(USER_KEY);
    window.localStorage.setItem(USER_KEY, JSON.stringify(user));
    window.localStorage.setItem('token', token);
  }

  // public getUser(): any {
  //   const user = window.localStorage.getItem(USER_KEY);
  //   if (user) {
  //     return JSON.parse(user);
  //   }
  //   return {};
  // }

  public isLoggedIn(): boolean {
    const token = window.localStorage.getItem('token');
    if (token) {
      return true;
    }
    return false;
  }

  public tokenExpired(): boolean {
    const JWT = window.localStorage.getItem('token');
    if (JWT){
      const expiry = JSON.parse(window.atob(JWT.split('.')[1])).exp;
      return (Math.floor((new Date).getTime() / 1000)) >= expiry
    }
    else {
      return false;
    }
  }

}
