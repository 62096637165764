<!-- login form -->
<div class="flex justify-center">
  <div class="w-[500px] overflow-y-auto px-4 sm:w-[320px]">
    <form class="my-10 lg:my-7 space-y-6" [formGroup]="signInForm" (ngSubmit)="onSubmit()">
      <div [routerLink]="['site']"
        class="flex items-center bg-dark-logo justify-center h-12 dark:bg-white-logo bg-200 bg-no-repeat bg-center">
      </div>
      <div class="text-center">
        <h2 class="mb-1 text-3xl font-semibold text-gray-700 dark:text-white">
          Hello There <span class="text-primary-500">!</span>
        </h2>
        <p class="text-sm text-gray-400 dark:text-night-200">Enter your credentials to access your account.</p>
      </div>
      <button type="button" (click)="signInWithGoogle()"
        class="flex w-full items-center justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-400 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 hover:bg-gray-50 dark:border-night-500 dark:bg-night-700 dark:text-night-200 dark:hover:bg-night-600">
        <svg-icon src="assets/icons/google-logo.svg" [svgClass]="'h-6 w-6 mr-2'"> </svg-icon>
        Log in with Google
      </button>

      <div
        class="my-4 flex items-center before:mt-0.5 before:flex-1 before:border-t before:border-gray-300 after:mt-0.5 after:flex-1 after:border-t after:border-gray-300 dark:before:border-night-500 dark:after:border-night-500">
        <p class="mx-4 mb-0 text-center text-sm text-gray-400 dark:text-night-400">or</p>
      </div>

      <div class="space-y-3 text-left">
        <div class="form__group">
          <div class="relative">
            <input type="text" id="email" class="peer block" placeholder=" " formControlName="email"
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
            <label for="email"
              class="absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-95 transform bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-95 peer-focus:px-2 peer-focus:text-primary-500 dark:bg-night-700 dark:text-night-200">
              Email address
            </label>
          </div>
          <div *ngIf="submitted && f.email.errors" class="mt-2 text-sm text-red-500 italic">
            <div *ngIf="f.email.errors.required">Email is required</div>
            <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
          </div>
        </div>

        <div class="form__group">
          <div class="relative">
            <input [type]="passwordTextType ? 'text' : 'password'" id="password" class="peer block" placeholder=" "
              formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
            <label for="password"
              class="absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-95 transform bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-95 peer-focus:px-2 peer-focus:text-primary-600 dark:bg-night-700 dark:text-night-200">
              Password
            </label>
            <span class="absolute top-2.5 right-5 cursor-pointer text-gray-400 dark:text-night-300"
              (click)="togglePasswordTextType()">
              <svg-icon [src]="!passwordTextType ? 'assets/icons/outline/eye-off.svg' : 'assets/icons/outline/eye.svg'"
                [svgClass]="'h-5 w-5'">
              </svg-icon>
            </span>
          </div>
          <div *ngIf="submitted && f.password.errors" class="mt-2 text-sm text-red-500 italic">
            <div *ngIf="f.password.errors.required">Password is required</div>
          </div>
        </div>
      </div>

      <div class="flex items-center justify-between space-x-3">
        <!--<div class="flex items-center">
          <input id="remember-me" name="remember-me" type="checkbox" />
          <label for="remember-me" class="ml-2 block text-sm text-gray-400 dark:text-night-200"> Remember me </label>
        </div> -->

        <div class="text-sm">
          <a routerLink="/auth/forgot-password" class="font-medium text-primary-500 hover:text-primary-400">
            Forgot your password?
          </a>
        </div>
      </div>

      <!-- Submit Button -->
      <button type="submit" [routerLink]="['site']"
        class="group relative mb-10 flex w-full justify-center rounded-md border border-transparent bg-primary-500 py-2 px-4 text-sm font-medium text-white hover:bg-primary-400">
        Sign in
      </button>

      <div *ngIf="submitted && isLoginFailed" class="bg-red-100 rounded-md px-4 py-2 border border-red-400">
        <div class="text-lg font-medium text-red-500">
          Incorrect Username or Password
        </div>
      </div>
      <div *ngIf="isLoginFailed && googleLoginFailed" class="bg-red-100 rounded-md px-4 py-2 border border-red-400">
        <div class="text-lg font-medium text-red-500">
          Please Sign Up First
        </div>
      </div>

      <div *ngIf="!checkPhoneVerified" class="bg-orange-200 rounded-md px-4 py-3 border border-orange-600">
        <div class="text-md font-medium text-orange-500">
          Your Phone Number has not been verified yet. Please verify.
        </div>
        <button type="button" (click)="verifyPhoneNumber()"
          class="mt-3 mb-2 flex w-full justify-center rounded-md bg-orange-400 py-2 px-4 text-sm font-medium text-white hover:bg-orange-300">
          Verify Phone Number
        </button>
      </div>

      <!-- Sign-up -->
      <div class="text-sm text-gray-400 dark:text-night-200">
        Not a Member yet? <a routerLink="/auth/sign-up" class="text-primary-500 hover:text-primary-400">Sign up</a>
      </div>
    </form>
  </div>
</div>
