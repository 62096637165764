import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';



@Injectable({
  providedIn: 'root',
})
export class MetaService {
  constructor(private http: HttpClient, private meta: Meta, private title: Title, @Inject(DOCUMENT)private document: Document) {}

  updateTitle(title: string) {
    this.meta.updateTag({ property: 'og:title', content: title });
    this.title.setTitle(title);
  }

  createCanonicalURL() {
    let link: HTMLLinkElement = this.document.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.document.head.appendChild(link);
    link.setAttribute('href', this.document.URL);
 }

  truncateWithEllipsis(text:any, maxLength:any) {
    if (!text){
      return
    }
    if (text.length <= maxLength) {
      return text; // If text is within the limit, return it unchanged
    }
  
    // Find the last space within the max length
    let truncatedText = text.substring(0, maxLength);
    const lastSpaceIndex = truncatedText.lastIndexOf(' ');
  
    if (lastSpaceIndex > -1) {
      truncatedText = truncatedText.substring(0, lastSpaceIndex); // Cut off at the last space to avoid breaking words
    }
    return truncatedText + '…'; // Append ellipsis
  }

  updateDescription(description: string) {
    this.meta.updateTag({ property: 'og:description', content: description });
    this.meta.updateTag({ name: 'description',content: description});
  }

  updateURL(url: string) {
    this.meta.updateTag({ property: 'og:url', content: url });
  }

  updateImage(imageUrl: string) {
    this.meta.updateTag({ property: 'og:image', content: imageUrl });
  }

  fetchOGDataForPage(pageName: string) {
    // Define the API endpoint based on the pageName
    const apiEndpoint = `${environment.apiUrl}/site/${pageName}`;

    // Make an HTTP GET request to your backend
    this.http.get(apiEndpoint).subscribe(
      (data: any) => {
        if (data) {
          // Update the OG tags with the data from the backend
          this.updateTitle(data.title);
          this.updateDescription(data.description);
          this.updateURL(data.url);
          this.updateImage(data.imageUrl);
        }
      },
      (error) => {
        console.error('Error fetching OG data:', error);
      }
    );
  }
}
