<!--
  Used in 'property-preview' to showcase each of the property images that were uploaded
    1. Button: next and previous
    2. Image location
-->
<div class="slider">
  <div>
    <div (click)="goToPrevious()" class="leftArrow h-full flex items-center justify-center pr-4"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="4" stroke="currentColor" class="w-6 h-6 svg-shadow">
      <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
    </svg>
    </div>
    <div (click)="goToNext()" class="rightArrow h-full flex items-center justify-center pl-4"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="4" stroke="currentColor" class="w-6 h-6 svg-shadow">
      <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
    </svg>
    </div>
  </div>
  <a [routerLink]="['/site/property-details/' + id]" target="_blank">
    <div class="slide" loading="lazy" [ngStyle]="{ 'background-image': getCurrentSlideUrl() }"></div>
  </a>
</div>