import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { first } from 'rxjs';
import { Property } from 'src/app/core/models/property.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-property-preview',
  templateUrl: './property-preview.component.html',
  styleUrls: ['./property-preview.component.css']
})
export class PropertyPreviewComponent implements OnInit {

  @Input() public property: any;
  userId: any;
  favId = 0;
  imageLoaded: boolean = false;

  imageLoading: boolean = true;
  
  constructor(private router: Router, private userService: UserService, private authService: AuthService, private storageService: StorageService) { }

  ngOnInit() {
    if (this.storageService.isLoggedIn() && !this.storageService.tokenExpired()) {
      this.authService.userDetails.subscribe((user:any)=>{
        this.userId = user.id;
        if (this.property.fans?.length != 0) {
          this.property.liked = false; 
          for (let fan of this.property.fans!) {
            if (fan.id == this.userId) {
              this.property.liked = true; 
              break;
            }
          }
      }
      // this.authService.getCurrentUser().pipe(first()).subscribe(user => {
      //   this.userId = user.id;
      //   if (this.property.fans?.length != 0) {
      //     this.property.liked = false; 
      //     for (let fan of this.property.fans!) {
      //       if (fan.id == this.userId) {
      //         this.property.liked = true; 
      //         break;
      //       }
      //     }
      //   }
       
      })
    }
  }

  toLocale(num: any) {
    return Math.round(parseInt(num)).toLocaleString("en-US")
  }

  goToDetails(id: any) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/site/property-details/' + id])
    );

    window.open(url, '_blank');
    // this.router.navigateByUrl('/site/property-details/' + id);
  }

  fromNow(date: any) {
    return moment(date).fromNow();
  }

  
  reloadPage() {
    // Get the current route URL
    const currentUrl = this.router.url;
  
    // Navigate to the same route, effectively reloading the page
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  toggleFavorite(property: any) {
    if (this.userId) {
      if (property.liked) {
        this.userService.unfavorite(this.userId, property.id).subscribe(() => {
          property.liked = false;
          this.reloadPage();
        })
      }
      else {
        this.userService.favorite(this.userId, property.id).subscribe(() => {
          property.liked = true;
        })
      }
    } else {
      this.guestFav(property.id);
    }

  }

  guestFav(pid: any) {
    this.favId = pid;
    setTimeout(() => {
      this.favId = 0;
    }, 2000);
  }

  goToProfile(id: any, role: any, username: any) {
    this.router.navigateByUrl("/" + role + "/" + id + "/" + username)
  }

 

// Add this method to your component
handleImageLoad() {
  this.imageLoading = false;
}


  share(prop: any, val: any) {
    prop.shared = !prop.shared;

    var strCmd = "document.getElementById('shared-popup" + prop.id + "').style.display = 'none'";
    var waitseconds = 2;
    var timeOutPeriod = waitseconds * 1000;
    setTimeout(strCmd, timeOutPeriod);

    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = window.location.origin + val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
