import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GoogleAuthProvider } from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { StorageService } from './storage.service';
import { User } from '../models/user.model';
import { Observable, throwError, catchError, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private storageService: StorageService, public afAuth: AngularFireAuth, private router: Router) { }

  private handleError(error: HttpErrorResponse): Observable<any> {
    if(error.status == 401){
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('theme');
    }
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
      return throwError('A client-side error occurred. Please check your internet connection and try again.');
    } else {
      
      if (error.status === 0) {
        return throwError('Unable to connect to the server. Please check your internet connection.');
      } else {
        return throwError('An unexpected server-side error occurred.');
      }
    }
  }

  login(data: any) {
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.post(`${environment.apiUrl}/auth/signin`, data).pipe(
        catchError(this.handleError)
    )};
  }

  loginWithGoogle(data: any) {
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.post(`${environment.apiUrl}/auth/signinWithGoogle`, data).pipe(
        catchError(this.handleError)
    )};
  }

  logout() {
    this.storageService.clean();
    this.router.navigateByUrl('auth');
    location.reload();
  }

  public getToken() {
    return localStorage.getItem('token');
  }

  private _userDetails: BehaviorSubject<{}> = new BehaviorSubject({});
  public get userDetails(): Observable<{}>{
    return this._userDetails.asObservable();
  }
  setAllUserDetails(data: any): void{
    this._userDetails.next(data);
  }

  getCurrentUser() {
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.get<User>(`${environment.apiUrl}/auth/getCurrentUser`).pipe(
        catchError(this.handleError)
    )};
  }

  // public isAuthenticated(): boolean {
  //   // get the token
  //   const token = this.getToken();
  //   // return a boolean reflecting
  //   // whether or not the token is expired
  //   return tokenNotExpired(null, token);
  // }

  // Sign in with Google
  GoogleAuth() {
    return this.AuthLogin(new GoogleAuthProvider());
  }
  // Auth logic to run auth providers
  AuthLogin(provider: any) {
    return this.afAuth
      .signInWithPopup(provider)
      .then((result) => {
        return result.user;
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
