import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Post } from '../models/post.model';
import { Observable, throwError, catchError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PostService {

  constructor(private http: HttpClient) { }

  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
      return throwError('A client-side error occurred. Please check your internet connection and try again.');
    } else {
      
      if (error.status === 0) {
        return throwError('Unable to connect to the server. Please check your internet connection.');
      } else {
        return throwError('An unexpected server-side error occurred.');
      }
    }
  }

  getAll() {
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.get<Post[]>(`${environment.apiUrl}/posts`).pipe(
        catchError(this.handleError)
    )};
  }

  getAllAdmin() {
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.get<Post[]>(`${environment.apiUrl}/posts/adminPosts`).pipe(
        catchError(this.handleError)
    )};
  }

  get(id: any) {
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.get<Post>(`${environment.apiUrl}/posts/${id}`).pipe(
        catchError(this.handleError)
    )};
  }

  getPublished() {
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.get<Post[]>(`${environment.apiUrl}/posts/find/published`).pipe(
        catchError(this.handleError)
    )};
  }

  create(data: any) {
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.post<Post>(`${environment.apiUrl}/posts`, data).pipe(
        catchError(this.handleError)
    )};
  }

  update(id: any, data: any) {
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.put<Post>(`${environment.apiUrl}/posts/${id}`, data).pipe(
        catchError(this.handleError)
    )};
  }

  delete(id: any) {
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.delete(`${environment.apiUrl}/posts/${id}`).pipe(
        catchError(this.handleError)
    )};
  }

  upload(id: any, file: File) {
    const formData: FormData = new FormData();

    formData.append('images', file);
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.post(`${environment.apiUrl}/posts/upload/${id}`, formData).pipe(
        catchError(this.handleError)
      ).subscribe(res => {
    })};
  }

  removeImage(data: any) {
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.post(`${environment.apiUrl}/post_images/remove`, data).pipe(
        catchError(this.handleError)
    )};
  }
}
